import React from "react";
import Image from "gatsby-image";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

class ImageGallery extends React.Component {
    componentDidMount() {
        this.swiperThumbnails=new Swiper('.gallery-thumbnails', {
            observer: true,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 1,
            spaceBetween: 5,
            watchSlidesProgress: true,
            breakpoints: {
                320: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 6
                },
                1280: {
                    slidesPerView: 7
                }
            }
        });

        this.swiper=new Swiper('.gallery', {
            observer: true,
            loop: true,
            lazy: {
                loadPrevNext: true
            },
            slidesPerView: 1,
            thumbs: {
                swiper: this.swiperThumbnails
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });
    }

    render() {
        let containerClasses = "w-100 gallery-container";

        if (this.props.containerClasses) containerClasses = containerClasses + ' ' + this.props.containerClasses;

        return(
            <div className={containerClasses}>
                <div className="swiper-container gallery">
                    <div className="swiper-wrapper">
                        { this.props.data.map((slide, index) => {
                            let caption =  slide.caption ? <figcaption className="absolute bottom-0 p-2 bg-white bg-opacity-75 w-full text-center text-xs sm:text-sm text-primary caption">{slide.caption}</figcaption> : false;

                            return(
                                <div className="flex justify-center items-center h-full swiper-slide" key={index}>
                                    <figure className="relative flex justify-center items-center w-full h-full">
                                        <Image fluid={slide.image}
                                               style={{width: '100%', maxHeight: '100%'}}
                                               imgStyle={{ objectFit: 'contain', opacity: 1 }}/>
                                        {caption}
                                    </figure>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="relative flex mt-6">
                    <button className="self-center swiper-button swiper-button-prev" aria-label="Previous"></button>

                    <div className="w-full swiper-container gallery-thumbnails">
                        <div className="swiper-wrapper">
                            { this.props.data.map((slide, index) => {
                                return(
                                    <div className="border-solid border-4 border-transparent swiper-slide" key={index}>
                                        <div className="thumbnail">
                                            <Image fluid={slide.image}
                                                   sizes={{ ...slide.image, aspectRatio: 1 / 1 }}
                                                   style={{width: '100%', maxHeight: '100%'}}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <button className="self-center swiper-button swiper-button-next" aria-label="Next"></button>
                </div>
            </div>
        )
    }
}

export default ImageGallery;