import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PageTitle from "../components/page-title";
import SEO from "../components/seo";
import ImageGallery from "../components/image-gallery";

export default function GalleryIndex({ data, location }) {
    const galleryImages = data.allMarkdownRemark.edges.map(obj => {
        let rObj = {};
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        rObj.caption = obj.node.frontmatter.caption;
        return rObj
    });

    return (
        <Layout location={location}
                masthead={{display: false}}>
            <SEO title="Gallery" />

            <PageTitle summary="Please peruse our diverse portfolio of projects."
                       title="Gallery" />

            <ImageGallery data={galleryImages} />
        </Layout>
    )
}

export const GalleryQuery = graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: "**/content/gallery/slides/*.md"
        }}, sort: {fields: fileAbsolutePath}) {
        edges {
          node {
            frontmatter {
              image {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                }
              }
              caption
            }
          }
        }
      }
    }
`
